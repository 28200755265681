/* alert start */

.accounting {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
}

.accounting > p {
  background-color: #212121;
  color: #ffffff;
  border-radius: 50px;
  padding: 15px 30px;
  margin: 0;
  animation: alertanim 0.3s;
  animation-fill-mode: forwards;
  display: flex;
  align-items: center;
}

.accounting > p > i {
  margin-right: 15px;
  margin-left: -10px;
}

@keyframes alertanim {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.accounting > p.error {
  background-color: #f44336;
}

.accounting > p.info {
  background-color: cornflowerblue;
}

.accounting > p.success {
  background-color: #8bc34a;
}

.accounting > p.warning {
  background-color: #ffa726;
}

/* alert end */
