.userInfo{
    display: flex;
    position: relative;
    &::after{
        position: absolute;
        content: '';
        width: 2px;
        height: calc(100% - 20px);
        top: 10px;
        right: 0;
        background-color:rgba($color: #707070, $alpha: .1);
    }
    .imageOuter{
        width: 85px;
         margin-right: 15px;
         margin-right: 15px;
         img{
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .detail{
        width: 100%;
        padding-right: 15px;
        h4{
            font-size: 20px;
            font-weight: 500;
            color: #304659;
        }
        ul{
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            li{
                font-size: 13px;
            }
        }
    }
}
.btns{
    display: block;
    text-align: right;
    button{
        text-align: center;
        width: 225px;
        margin-bottom: 12px;
        &:first-child{
            background-color: #9C8657;
        }
        &:nth-child(2){
            background-color: #304659;
        }
    }
}
.medicationDetail{
    border-bottom: 1px solid rgba($color: #707070, $alpha: .27);
    padding-bottom: 20px;
    margin-bottom: 28px;
    h3{
        font-size: 28px;
        line-height: 34px;
        color: rgba($color: #222222, $alpha: .75);
        font-weight: 600;
        margin-bottom: 25px
    }
    h5{
        font-size: 18px;
        font-weight: 500;
        color: rgba($color: #222222, $alpha: .75);
        margin-bottom: 15px;
    }
    table{
        width: 100%;
        tr{
            td{
                padding: 2px 10px;
                font-size: 15px;
                &:first-child{
                    width: 140px;
                    text-align: right;
                    color: rgba($color: #222222, $alpha: .5);
                }
                &:nth-child(2){
                    color: rgba($color: #222222, $alpha: .75);
                }
            }
        }
    }
}
.sideDrawerPrescription{
    .MuiDrawer-paperAnchorRight{
        padding: 40px;
        max-width: 830px;
    }
}
.directionText{
    h5, p{
        font-size: 15px;
        line-height: 19px;
        color: rgba($color: #222222, $alpha: .75);
    }
    h5{
        margin-bottom: 6px;
    }
    p{
        margin-bottom: 30px;
    }
}
.clinicDetail{
    padding: 28px 60px;
    border: 1px solid rgba($color: #707070, $alpha: .15);
    position: relative;
    min-height: 120px;
    overflow: hidden;
    .iconLabel{
        background-color: #E0F1FF;
        position: absolute;
        display: inline-flex;
        width: 43px;
        height: 43px;
        top: -8px;
        left: -8px;
        border-radius: 50%;
    }
    h5{
        font-size: 20px;
        line-height: 24px;
        color: rgba($color: #222222, $alpha: .75);
    }
    ul{
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;
        li{
            font-size: 15px;
            line-height: 19px;
        }
    }
}
.prescriptionViewBtn{
    box-shadow: none !important;
    width: 97px;
    height: 30px;
    border-radius: 15px !important;
    border: 1px solid rgba($color: #707070, $alpha: .2) !important;
    color: #304659 !important;
    font-size: 15px !important;
    text-align: center;
}
