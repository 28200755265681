/* autosuggest start */
body {
  font-family: Lato;
}
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 400px;
  height: 50px;
  padding-left: 21px;
  padding-right: 21px;
  font-size: 18px;
  color: rgba(34, 34, 34, 0.5);
  font-family: 'Lato';
  font-weight: 400;
  border: 1px solid #707070;
  background-color: #f5f6f7;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  max-height: 120px;
  border: 1px solid #f5f6f7;
  background-color: #f5f6f7;
  font-size: 18px;
  color: rgba(34, 34, 34, 0.5);
  font-family: 'Lato';
  font-weight: 400;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  margin-bottom: 100px;
  overflow: auto;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  height: 60px;
  color: rgba(34, 34, 34, 0.5);
  /* color: #ffffff; */
  align-items: center;
  justify-self: center;
  /* padding: 10px 20px; */
}

@media only screen and (min-width: 0px) {
  .react-autosuggest__input {
    width: 210px;
    height: 50px;
    padding-left: 21px;
    padding-right: 21px;
    font-size: 18px;
    color: rgba(34, 34, 34, 0.5);
    font-family: 'Lato';
    font-weight: 400;
    border: 1px solid #707070;
    background-color: #f5f6f7;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    max-height: 120px;
    width: 252px;
    border: 1px solid #f5f6f7;
    background-color: #f5f6f7;
    font-size: 18px;
    color: rgba(34, 34, 34, 0.5);
    font-family: 'Lato';
    font-weight: 400;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    margin-bottom: 100px;
    overflow: auto;
  }
}

@media only screen and (min-width: 600px) {
  .react-autosuggest__input {
    width: 380px;
    height: 50px;
    padding-left: 21px;
    padding-right: 21px;
    font-size: 18px;
    color: rgba(34, 34, 34, 0.5);
    font-family: 'Lato';
    font-weight: 400;
    border: 1px solid #707070;
    background-color: #f5f6f7;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    max-height: 120px;
    width: 422px;
    border: 1px solid #f5f6f7;
    background-color: #f5f6f7;
    font-size: 18px;
    color: rgba(34, 34, 34, 0.5);
    font-family: 'Lato';
    font-weight: 400;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    margin-bottom: 100px;
    overflow: auto;
  }
}

@media only screen and (min-width: 960px) {
  .react-autosuggest__input {
    width: 400px;
    height: 46px;
    padding-left: 21px;
    padding-right: 21px;
    font-size: 18px;
    color: rgba(34, 34, 34, 0.5);
    font-family: 'Lato';
    font-weight: 400;
    border: 1px solid #707070;
    background-color: #f5f6f7;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    max-height: 120px;
    border: 1px solid #f5f6f7;
    background-color: #f5f6f7;
    font-size: 18px;
    color: rgba(34, 34, 34, 0.5);
    font-family: 'Lato';
    font-weight: 400;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    margin-bottom: 100px;
    overflow: auto;
  }
}

/* autosuggest start end */
