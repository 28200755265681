.menu-list-custom {
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: #003265 !important;
    .MuiSvgIcon-root {
      color: #fff;
    }
    .text-color-link {
      color: #fff;
      font-size: 18px;
    }
  }
  [class*='makeStyles-menuText'] {
    background-color: #fff !important;
  }
}
.text-color-link {
  font-size: 18px !important;
}
.App {
  background-color: #f6f7fb;
  height: auto;
}
body {
  background-color: #f6f7fb;
}
.main-content {
  padding-bottom: 75px;
  background-color: #f6f7fb;
  margin-left: 21%;
  margin-top: 30px;
  width: 97%;
  .card-head {
    .MuiCardHeader-content {
      text-align: left;
      .MuiTypography-gutterBottom {
        font-size: 15px;
        margin: 0;
      }
    }
  }
}

.pagination-contant {
  width: 100%;
  .pagination-custom {
    float: right;
    margin-top: 15px;
    .MuiPagination-ul {
      li {
        margin: 2px;
        button {
          color: #fff;
          border-radius: 10px;
          background-color: #003265;
        }
      }
    }
  }
}
.input-search-color {
  background-color: #fff;
  color: #003265;
  svg.MuiSvgIcon-root {
    color: #003265;
  }
}
label#demo-simple-select-outlined-label {
  color: #003265;
}

[class*='PrivateRadioButtonIcon-checked'] {
  //     border-color: #F79329 !important;
  //   }
  // .{
  background-color: #003265;
  color: #003265;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
}

.MuiOutlinedInput-input {
  padding: 14.5px 14px !important;
}

.border-none-input {
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiOutlinedInput-adornedEnd {
    border-radius: 10px;
  }
  .drop-down-upcoming {
    border-radius: 10px;
    .MuiInputBase-formControl {
      border-radius: 10px;
    }
  }
}

.drop-down-custom-detail {
  // float: right;
  .MuiMenu-list {
    background-color: #003265 !important;
    color: #fff;
  }
  .MuiListItemText-root.text-drop-down {
    margin-left: -25px;
  }
  .MuiListItemText-root.text-drop-down-no-margin {
    margin-left: 0px;
  }
  .MuiSvgIcon-fontSizeSmall {
    color: #fff;
  }
}
.MuiPopover-paper.MuiPaper-elevation0.MuiPaper-rounded {
  margin-left: -30px;
}

// .drop-down-custom-detail{
//     width: 45%;
//     float: right;
//     option{
//         background-color: #003265 !important;
//         color: #fff;
//         :hover{
//             background-color: #003265 !important;
//             color: #fff;
//         }
//     }
//     select#filled-age-native-simple{
//         color: #fff;
//     }

//     .MuiSelect-filled.MuiSelect-filled{
//         padding: 0;
//     }
//     background-color: #003265;
//     .MuiInputLabel-animated{
//     //    .MuiInputLabel-filled{
//         margin-top: -9px;
//         color: #fff;
//         // }
//     }
//     .MuiFormLabel-root.Mui-focused{
//         color: #fff;
//         margin-top: 5px;
//     }
//     .MuiSelect-iconFilled{
//         color: #fff;

//     }
//     .MuiFilledInput-underline{
//         height: 36px;
//     }
// }

.date-style-custom {
  border: solid 0.5px rgba(112, 112, 112, 0.5) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 155px !important;
  margin-bottom: 15px !important;
  .MuiInput-underline:before {
    border: none;
  }
  .MuiInput-underline:after {
    border: none;
  }
  .MuiInput-underline:hover {
    border: none;
  }
}

.customt-select {
  width: 100%;
  margin-bottom: 15px !important;
  .MuiSelect-outlined.MuiSelect-outlined {
    padding: 10px;
  }
  [class*='MuiOutlinedInput-notchedOutline'] {
    border: none;
    background-color: #f2f6f8;
  }
  .MuiSelect-iconOutlined {
    z-index: 999;
  }
}
.MuiFormControl-root.customt-select {
  margin-bottom: 12px;
}

.upcoming-radio {
  margin-bottom: -10px;
  .MuiIconButton-label {
    width: 16px;
    .MuiSvgIcon-root {
      width: 16px;
    }
  }
}

// proceed-alert
.proceed-alert {
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 34px 50px;
  color: #ffffff;
  .alertDesc {
    text-align: left !important;
    .img {
      display: inline-flex;
      align-items: center;
      span {
        margin-left: 7px;
        display: inline-block;
        font-size: 15px;
        font-weight: 600;
        color: #ffffff;
      }
    }
    p {
      font-size: 25px;
      color: #3293da;
      margin: 5px 0;
      line-height: 30px;
      margin-bottom: 0;
    }
    h6 {
      font-size: 36px;
      line-height: 40px;
      font-weight: 400;
      margin: 0 !important;
    }
  }
  .proceed-btn {
    margin-right: 65px !important;
    .button-custom {
      width: 298px;
      border-radius: 30px;
      box-shadow: none !important;
      height: 60px;
    }
  }
  &.blue {
    background-color: #003265;
    .alertDesc {
      p {
        color: #3293da;
      }
    }
    .proceed-btn {
      .button-custom {
        background-color: #3293da;
        color: #ffffff;
      }
    }
  }
  &.orange {
    background-color: #db1919;
    .alertDesc {
      p {
        color: #ffab0b;
      }
    }
    .proceed-btn {
      .button-custom {
        background-color: #ffab0b;
        color: #222222;
      }
    }
  }
}
// .show-proceed{
//     .sideDrawer{
//         padding-top: 0px !important;
//         margin-top: 0 !important;
//     }
// }

.sticky-wrapper {
  position: relative;
  height: 3rem; /* We need to change this value */
}
header.sticky {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.sideDrawer {
  z-index: 999 !important;
}
header {
  z-index: 1000;
}
header.sticky + .main-content {
  top: 100px !important;
  .sideDrawer {
    top: 100px !important;
  }
}
.main-content {
  padding-top: 30px !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  padding-left: 340px;
  position: relative;
  .sideDrawer {
    position: absolute;
    left: 0 !important;
    top: 0 !important;
    width: 300px;
    height: 100%;
    min-height: 100vh;
  }
}
.sticky .sideDrawer {
  padding-top: 100px !important;
}
.MuiDrawer-paper {
  &::-webkit-scrollbar {
    width: 5px;
    margin-right: -25px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(183, 183, 183, 0.3);
    border-radius: 5px;
  }
}
.filter-list {
  position: relative;
  display: block;
  padding-left: 25px;
  color: rgba(112, 112, 112, 0.5);
  width: 100%;
  &::before {
    content: '';
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-color: #f1f1f1;
  }
}
.MuiList-root {
  > div {
    padding-left: 25px !important;
  }
}

// User Cards DB
.userCardOuter {
  margin-bottom: 20px;
  padding: 10px;
  > div {
    background-color: #ffffff;
  }
  h5,
  h6,
  p {
    margin: 0;
  }
  .userInfo {
    text-align: left;
  }
  .userDetail {
    display: flex;
    padding: 25px;
    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin-right: 15px;
    }
    h5 {
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      color: #304659;
    }
    text {
      margin: 0;
      font-size: 12px;
      line-height: 18px;
      color: #304659;
    }
    p {
      font-size: 13px;
      line-height: 16px;
      color: #adb6bd;
    }
  }
  .appointTime {
    display: flex;
    align-items: center;
    border-top: 2px solid #f0f0f0;
    border-bottom: 2px solid #f0f0f0;
    padding: 20px 25px;
    justify-content: center;
    h5 {
      font-size: 28px;
      line-height: 34px;
      color: #304659;
      margin-right: 10px;
      font-weight: 400;
    }
    h6 {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #9aa4ae;
      margin-left: 10px;
    }
  }
  .detailBtn {
    padding: 20px 10px;
    text-align: center;
    a {
      font-size: 11px;
      line-height: 13px;
      color: #1b63eb;
    }
  }
  .actions {
    margin-top: 8px;
    > span {
      svg {
        &:first-child {
          margin-right: 5px;
        }
      }
    }
  }
  &.double {
    .userDetailOuter {
      display: flex;
      .userDetail {
        width: 50%;
      }
    }
  }
}

.MuiInput-underline:before {
  content: none !important;
}

/*
  this will make all chips look like buttons.
  need to handle it in move flexible way
  Need to remove !important
*/

.MuiChip-root {
  border-radius: 3px !important;
  background-color: #003265 !important;
  color: #fff !important;
  margin-right: 10px !important;
}

.calendarMain {
  width: 100%;
  display: flex;
  position: relative;
  > div {
    display: inline-block;
  }
  .addNewBtn {
    margin-top: 50px;
    .MuiButtonBase-root {
      width: 100%;
      box-shadow: none;
      background-color: #003265;
      color: #ffffff;
      border-radius: 0;
      padding: 10px;
    }
  }
  .calendarBtns {
    // width: 25%;
    text-align: left;

    > div {
      padding: 15px;
      background-color: #ffffff;
      margin-bottom: 15px;
      &.shadows {
        box-shadow: 0 0 4px #c4c4c4;
      }
    }
    .limits {
      padding: 25px 10px;
      text-align: center;
      h4 {
        font-size: 16px;
        margin: 0 0 10px;
        color: #003265;
      }
      input {
        width: 100%;
        padding: 5px;
        font-size: 20px;
        border: 1px solid grey;
        color: #003265;
        text-align: center;
      }
    }
    .acceptNewPatient {
      padding: 30px 10px;
      background-color: #003265;
      text-align: center;
      color: #ffffff;
      h4 {
        font-size: 15px;
        margin: 0px;
        color: #ffffff;
      }
    }
    h5 {
      font-size: 18px;
      line-height: 24px;
      color: #3d525b;
      margin-bottom: 0;
    }

    li:first-child {
      padding-left: 20px;
      &::before {
        margin-left: 0px;
      }
    }
    ul.vertical {
      flex-direction: column;
      li {
        padding-left: 20px;
        &::before {
          margin-left: 0px;
        }
      }
    }
    ul {
      display: flex;
      padding-left: 0;
      list-style-type: none;
      li {
        color: #828d92;
        position: relative;
        padding-left: 60px;
        margin-bottom: 10px;
        cursor: pointer;
        &::before {
          margin-left: 40px;
          position: absolute;
          content: '';
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          left: 0;
          top: 4px;
        }
        &:first-child {
          &::before {
            background-color: rgba(255, 161, 64, 0.3);
          }
        }
        &:nth-child(2) {
          &::before {
            background-color: rgba(0, 207, 195, 0.3);
          }
        }
        &:nth-child(3) {
          &::before {
            background-color: rgba(248, 64, 71, 0.3);
          }
        }
        &:nth-child(4) {
          &::before {
            background-color: rgba(0, 0, 0, 1);
          }
        }
      }
    }
    .addEventBtn {
      margin-top: 15px;
      display: inline-block;
      padding: 5px 10px;
      border-radius: 5px;
      background-color: #1368c8;
      font-size: 14px;
      text-align: center;
      color: #fff;
      cursor: pointer;
    }
  }
  .calendarOuter {
    width: 100%;
    table {
      border: none;
    }
    .fc-day-header {
      background-color: #f0f6f7;
      color: #3d525b;
      padding: 10px !important;
      border-color: #f0f6f7;
      border-width: 0;
      vertical-align: middle;
    }
    .fc-button:nth-child(2) {
      border-radius: 0;
    }
    .fc-button {
      background-color: #ffffff;
      border-color: #e6e6e6;
      line-height: unset !important;
      color: #3d525b;
      text-transform: capitalize;
      margin-left: 0;
      &:focus {
        outline: none !important;
        box-shadow: none;
      }
      &.fc-button-active,
      &:active {
        color: #3d525b;
        border-color: #ffffff;
        background-color: #ffffff;
        box-shadow: 0 0 2px grey;
      }
    }
    .fc-icon {
      margin-top: -4px;
      font-weight: 700;
      color: #3d525b;
      font-size: 16px !important;
    }
    .fc-center {
      h2 {
        color: #3d525b;
        font-size: 22px;
        line-height: 28px;
      }
    }
    .fc-day-grid-event {
      background-color: #1368c8;
      color: #1368c8;
      color: #ffffff;
    }
  }
  .fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
    float: left;
  }
  .fc-day-top {
    padding-top: 10px;
    padding-left: 15px;
  }
  .fc-today {
    background-color: #f0f2f7;
  }
  .fc-content {
    .fc-time {
      font-size: 14px;
    }
  }
  .calendarLoader {
    position: absolute;
    width: 100%;
    background: #80808036;
    height: 100%;
    z-index: 999;
  }
}

.patientDetailMain {
  padding: 37px 32px 28px 50px;
  background-color: #f0ede6;
  position: relative;
  display: flex;
  .leftCard {
    width: 200px;
    margin-right: 60px;
    text-align: center;
    > img {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      border: 2px solid #ffffff;
      margin-bottom: 14px;
      object-fit: cover;
    }
    .patientName {
      font-size: 19px;
      line-height: 22px;
      font-weight: 600;
      color: #222222;
      margin-bottom: 5px;
      margin-top: 0;
    }
    .email {
      margin-top: 0;
      font-size: 12px;
      line-height: 18px;
      color: rgba(34, 34, 34, 0.47);
      margin-bottom: 10px;
    }
    .counts {
      display: block;
      margin: 0 auto;
      position: relative;
      &::before {
        content: '';
        background-color: rgba(34, 34, 34, 0.3);
        width: 2px;
        height: 38px;
        position: absolute;
        top: 0;
        left: 99px;
      }
      > div {
        width: 75px;
        display: inline-block;
        text-align: center;
        padding: 0 15px;
      }
      .label {
        color: rgba(34, 34, 34, 0.47);
        font-size: 10px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 0;
      }
      .count {
        color: rgba(34, 34, 34, 1);
        font-size: 16px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .options {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      img {
        margin: 0 5px;
      }
    }
    .sendBtn {
      margin-top: 16px;
      color: rgba(34, 34, 34, 1);
      background-color: rgba(156, 134, 87, 0.2);
      border-color: #f0ede6;
      font-size: 15px;
      padding: 15px;
    }
  }
  .rightCard {
    position: relative;
    padding-top: 25px;
    width: calc(100% - 260px) !important;
    .moreInfo {
      display: flex;
      flex-wrap: wrap;
      div {
        width: 20%;
        margin-right: 5%;
        text-align: left;
        padding-bottom: 7px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.1);
        margin-bottom: 20px;
        &.address {
          width: 45%;
        }
        h4 {
          font-size: 15px;
          font-weight: bold;
          margin-top: 0;
          margin-bottom: 5px;
          color: rgba(34, 34, 34, 0.3);
        }
        p {
          font-size: 12px;
          margin-top: 0;
          margin-bottom: 5px;
          color: rgba(34, 34, 34, 0.91);
        }
      }
    }
    .cumulativeRisk {
      h4 {
        text-align: left;
        font-size: 15px;
        margin-top: 0;
        margin-bottom: 5px;
        color: rgba(34, 34, 34, 0.5);
        font-weight: 400;
        span {
          font-weight: bold;
        }
      }
    }
  }
}
.tabsMain {
  .MuiAppBar-colorDefault {
    background-color: rgba(156, 134, 87, 0.2);
    box-shadow: none;
  }
  .MuiTab-root {
    padding: 20px 15px;
    min-width: unset;
  }
  .MuiTab-wrapper {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    color: rgba(34, 34, 34, 0.75);
  }
  .Mui-selected {
    .MuiTab-wrapper {
      font-weight: 900;
    }
  }
  .MuiTabs-indicator {
    height: 7px !important;
    background-color: rgba(34, 34, 34, 0.75);
  }
}
.tabHeading {
  font-size: 18px;
  color: #9c8657;
  margin: 25px 0;
  text-align: left;
}
.tabsMain {
  .MuiGrid-grid-lg-3 {
    padding: 0 15px;
  }
}
.doctorCard {
  text-align: center;
  background-color: #ffffff;
  margin-bottom: 40px;
  img {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    border: 3px solid #ffffff;
    margin: 35px 0 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
  h4 {
    font-size: 17px;
    line-height: 21px;
    margin: 0;
    color: rgba(34, 34, 34, 0.75);
  }
  p {
    font-size: 12px;
    line-height: 15px;
    margin: 6px 0 15px;
    color: rgba(112, 112, 112, 0.5);
  }
  .MuiFab-extended {
    box-shadow: none !important;
    background-color: #1b63eb;
    font-size: 12px;
    line-height: 14px;
    padding: 4px 12px !important;
    height: 24px !important;
    margin-bottom: 24px;
  }
  .MuiFab-extended:hover {
    background-color: #1b63eb;
  }
}
.bottomBtns {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  button {
    cursor: pointer;
    border: none;
    font-size: 10px;
    padding: 14px;
    background-color: #f0ede6;
    width: calc(50% - 2px);
    color: rgba(34, 34, 34, 0.75);
    i {
      margin-right: 5px;
    }
  }
}
.closePage {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  color: rgba(34, 34, 34, 0.75);
  i {
    color: #9c8657;
    font-size: 16px;
    margin-left: 5px;
  }
}
:focus {
  outline: none !important;
}
.custom-swipable-drawer {
  .MuiDrawer-paperAnchorRight {
    width: 80%;
  }
}
.mini-custom-swipable-drawer {
  .MuiDrawer-paperAnchorRight {
    width: 50%;
  }
}
.patientDetailEventTable1 {
  td {
    max-width: 15px;
  }
}
.patientDetailEventTable {
  td {
    max-width: 175px;
  }
  p {
    font-size: 12px;
    line-height: 16px;
    color: rgba($color: #222222, $alpha: 0.75);
    &.topicDetail {
      font-size: 13px;
      font-weight: 600;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &.headNames {
      color: rgba($color: #9c8657, $alpha: 0.75);
    }
  }
  h4 {
    font-size: 15px;
    font-weight: 600;
    color: rgba($color: #222222, $alpha: 0.75);
    overflow: hidden;
  }
  .viewBtn {
    background-color: #9c8657 !important;
  }
  .seatsAvail {
    color: #3293da;
  }
  .images {
    img {
      width: 35px;
      height: 35px;
      border-radius: 18px;
    }
  }
}
.patientDetailEventTable1 {
  td {
    max-width: 30px;
  }
}
.clinicianEvents {
  p {
    color: #304659 !important;
    &.headNames {
      color: rgba($color: #707070, $alpha: 0.5) !important;
    }
  }
  h4,
  .seatsAvail {
    color: #304659;
  }
}
