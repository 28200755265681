html {
  height: 100%;
}
body {
  font-family: 'Lato', sans-serif;
}
ul#client-list > li,
ul#provider-list > li,
ul#address-list > li {
  margin-top: 2px;
  cursor: pointer;
}
.address-selection {
  cursor: pointer;
}
.address-selection::after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
table#rate-card-table tr td {
  border-bottom: 1px solid #f1f1f1;
}
table#rate-card-table th,
table#rate-card-table td {
  padding: 10px 5px;
}

.loader {
	border-top-color: #3498db;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
