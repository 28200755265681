.upcoming-events
  > .react-datepicker-wrapper
  > .react-datepicker__input-container
  > input {
  width: 100%;
  height: 32px;
}

input {
  color: black !important;
}

a.text-blue {
  color: rgb(0, 58, 112) !important;
}

.PhoneInputInput {
 border-color: #DEE2E6 !important;
 font-size: 0.875rem/* 14px */!important;; 
  line-height: 1.25rem/* 20px */!important;
  height: 3rem/* 48px */!important;
}