/* .select-custom-class .Select-multi-value-wrapper {
    padding-right: 20px;
  }
  
  .select-custom-class .Select-menu-outer {
    text-align: left;
    width: 200px;
  }
  
  .Select-placeholder, .Select--single > .Select-control {
    width: 200px;
  }
  
  .Select-control, .Select-clear-zone, .Select-arrow-zone {
    display: inherit;
  }
  
  .select-custom-class .Select-multi-value-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }
  
  .Select-clear-zone {
    position: absolute;
    top: 8px;
    right: 20px;
  }
  
  .Select-arrow-zone {
    position: absolute;
    top: 8px;
    right: 0px;
  } */

/* .Select-placeholder, .Select--single > .Select-control .Select-value {
  position: relative;
  padding-left: 0;
}

.Select-control, .Select-clear-zone, .Select-arrow-zone {
  display: inherit;
  width: '500px';
}

.select-custom-class .Select-multi-value-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.Select-clear-zone {
  position: absolute;
  top: 8px;
  right: 20px;
}

.Select-arrow-zone {
  position: absolute;
  top: 8px;
  right: 0px;
} */

.react-select-container {
  background-color: "red";
}

.react-select-container > .react-select__control {
  /* background-color: green; */
}

.react-select-container > .react-select__control > .react-select__value-container {
  /* height: 50px; */
  width: 480px;
}

@media only screen and (min-width: 0px) {
  .react-select-container > .react-select__control > .react-select__value-container {
    height: 20px;
    width: 223px;
  }
}

@media only screen and (min-width: 600px) {
  .react-select-container > .react-select__control > .react-select__value-container {
    height: 40px;
    width: 404px;
  }
}

@media only screen and (min-width: 960px) {
  .react-select-container > .react-select__control > .react-select__value-container {
    height: 50px;
    /* width: 480px; */
    width: 463px;
  }
}

.ck-editor__editable {
  min-height: 300px;
}