@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    a {
        cursor: pointer;
    }
}

