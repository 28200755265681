.MuiDropzoneArea-root {
  min-height: 100px;
}

.customDropzone {
  min-height: 0 !important;
  width: 100%;
  height: 100%;
}

.customDropzone p {
  font-size: 14px;
}
.react-datepicker-wrapper {
  width: 100%;
}
.Setting-inputsty-4 .MuiInput-root {
  height: 100%;
}

.default-dropzone {
  height: 100%;
  min-height: 0px !important;
}

.ql-editor {
  min-height: 200px;
  max-height: 300px;
}

.ql-toolbar.ql-snow {
  text-align: left;
}
