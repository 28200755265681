@layer components {
    /* 
    PRIMARY NAVIGATION - Admin
    */
    nav.menu-top-level {
        height: calc(100vh - 11.5rem);
        @apply flex-grow pt-2 px-4 overflow-y-auto;
        @apply xl:h-auto xl:overflow-visible xl:order-2 xl:pl-6 xl:p-0;
    }
    ul.primary-menu {
        @apply mb-8 text-sm font-medium xl:mb-0;
    }
    /* in-active state */
    ul.primary-menu > li.menu-item-top-level {
        @apply relative mb-1 rounded border border-white xl:mb-0;
    }
    ul.primary-menu > li.menu-item-top-level:hover {
        @apply rounded border bg-gray-100 border-gray-200;
    }
    ul.primary-menu > li.menu-item-top-level > a {
        @apply flex items-center pr-4 p-3 rounded text-gray-500 xl:p-2;
    }
    ul.primary-menu > li.menu-item-top-level > a > span.menu-icon {
        @apply inline-block mr-3 xl:mr-1.5;
    }
    ul.primary-menu > li.menu-item-top-level > a > span.menu-icon > svg {
        @apply text-gray-300 w-5 h-5;
    }
    /* active state */
    ul.primary-menu > li.menu-item-top-level.active > a {
        @apply flex items-center pr-4 p-3 rounded xl:p-2;
    }
    ul.primary-menu li.menu-item-top-level.active,
    ul.primary-menu li.menu-item-top-level.active:hover {
        @apply bg-gray-100 rounded border border-gray-200;
    }
    ul.primary-menu li.menu-item-top-level.active ul.submenu {
        @apply block xl:hidden;
    }
    ul.primary-menu > li.menu-item-top-level.active > a > span.menu-icon {
        @apply inline-block mr-3 text-gray-400 xl:mr-1.5;
    }
    ul.primary-menu > li.menu-item-top-level.active > a > span.menu-icon > svg {
        @apply w-5 h-5 text-gray-400;
    }
    /* submenu */
    ul.primary-menu li.menu-item-top-level ul.submenu {
        @apply py-2 mx-2 text-xs font-normal text-gray-500 bg-gray-100 hidden xl:absolute xl:top-full xl:z-50 xl:rounded xl:border xl:mx-0 xl:px-2;
    }
    
    @media only screen and (min-width: 1280px) {
        ul.primary-menu li.menu-item-top-level ul.submenu {
            margin-left: -1px;
        }
    }
    ul.primary-menu li.menu-item-top-level:hover > ul.submenu {
        @apply hidden xl:block;
    }
    ul.primary-menu li.menu-item-top-level.active:hover > ul.submenu {
        @apply block xl:block;
    }
    ul.primary-menu li.menu-item-top-level ul.submenu li {
        @apply mb-0.5;
    }
    ul.primary-menu li.menu-item-top-level ul.submenu li a {
        @apply block pl-8 pr-5 py-2 rounded whitespace-nowrap xl:px-4;
    }
    ul.primary-menu li.menu-item-top-level ul.submenu li a:hover {
        @apply text-gray-50 bg-blue-700;
    }
    ul.primary-menu li.menu-item-top-level ul.submenu li.active a {
        @apply text-gray-50 bg-blue-700 xl:px-4;
    }
    /* staff avatar */
    .menu-staff-profile {
        @apply items-center flex-grow-0 pb-5 px-4 border-b xl:order-3 xl:flex-shrink xl:border-none xl:pb-0;
    }
    span.user-role {
        @apply px-3 pt-0.5 pb-1 rounded-full text-xxs text-white bg-brand-gold-dark;
        @apply xl:block xl:p-0 xl:rounded-none xl:bg-white xl:text-gray-400 xl:text-right;
    }
}