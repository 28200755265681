.rbc-day-bg.rbc-today {
  background-color: rgba(255, 251, 169, 51%);
}

/* .rbc-day-bg {
    width: 142px;
    height: 100px;
} */
.rbc-month-row {
  border: 1px solid rgba(48, 70, 89, 50%);
}

.rbc-header {
  /* border: 1px solid rgba(48, 70, 89, 50%); */
  padding: 0;
  border: 0;
}

.rbc-month-view {
  /* width: 142px; */
  height: 100px;
  border: 1px solid rgba(48, 70, 89, 50%);
  background-color: #ffffff;
}

.rbc-date-cell {
  font-size: 21px;
  line-height: 25px;
  color: #6d6d6d;
  font-weight: 400;
  font-family: "Lato";
  text-align: right;
  /* border: 1px solid rgba(48, 70, 89, 50%); */
}

.rbc-event {
  background-color: inherit;
  &.rbc-selected {
    background-color: inherit;
    border: none;
  }
  }
